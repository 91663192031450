<template>
  <div class="wrapper flex align-start justify-between">
    <div class="flex-sub">
      <el-form :model="handoverForm"
               label-width="140px"
               :rules="rules" ref="handoverForm" class="add-form">

        <el-form-item label="姓名" prop="xingming">
          <el-input v-model="handoverForm.xingming" disabled placeholder="请填写姓名"></el-input>
        </el-form-item>
        <el-form-item label="岗位名称" prop="gangwei">
          <el-input v-model="handoverForm.gangwei" placeholder="请填写岗位名称"></el-input>
        </el-form-item>

        <el-form-item label="提交报告日期" prop="resignSubDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="handoverForm.resignSubDate"
                          type="date"
                          placeholder="请选择提交报告日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="最后工作日期" prop="lizhiriqi">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="handoverForm.lizhiriqi"
                          type="date"
                          placeholder="请选择最后工作日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="社保缴纳至" prop="socialInsuranceEnd">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="handoverForm.socialInsuranceEnd"
                          type="date"
                          placeholder="请选择社保缴纳至">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否有其他协议" prop="anyAgreement">
          <el-select v-model="handoverForm.anyAgreement" placeholder="请选择是否有其他协议（培训协议等）" class="w-all">
            <el-option
                v-for="item in yesOrNo"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="离职原因" prop="lizhiyuanying">
          <el-select v-model="handoverForm.lizhiyuanying" placeholder="请选择离职原因" class="w-all">
            <el-option
                v-for="item in  reasonList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="人事核算余下工资" prop="resignSalary">
          <el-input v-model="handoverForm.resignSalary" placeholder="请填写人事核算余下工资" type="number">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>


        <el-form-item label="离职工资发放月份" prop="resignSalaryReleaseMonth">

          <el-input type="number" v-model="handoverForm.resignSalaryReleaseMonth" placeholder="请填写离职工资发放月份">
            <template slot="append">月份</template>
          </el-input>
        </el-form-item>


        <el-form-item label="签订日期" prop="resignSignDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="handoverForm.resignSignDate"
                          type="date"
                          placeholder="请选择签订日期">
          </el-date-picker>
        </el-form-item>


        <el-form-item label-width="0" class="flex justify-around">
          <el-button class="submit-btn" type="primary" @click="submitForm('handoverForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-sub  preview padding-lr">
      <div class="preview-info">
        <div class="preview-tips" v-if="!sys_offer_file">保存后可预览</div>
        <iframe v-else ref="iframe" frameborder="0" style="width: 100%;height: calc(100vh - 335px);"></iframe>
      </div>
      <div class="flex align-center justify-around margin-top">
        <el-button class="submit-btn margin-right" type="primary" @click="downOffer">下载离职交接表
        </el-button>
        <el-upload
            ref="upBtn"
            class="upload-demo margin-right"
            :action="actions"
            :data="{token}"
            :on-success="handleSuccess"
            :limit="1"
            :show-file-list="false"
        >
          <el-button class="submit-btn" type="warning">上传离职交接表反馈</el-button>
        </el-upload>

      </div>
      <div class="flex align-center justify-center margin-top" v-if="upload_file">
        <div>反馈附件：</div>
        <el-link v-if="upload_file" :href="upload_file" target="_blank" type="primary">
          下载离职交接表反馈附件
        </el-link>
      </div>
    </div>

  </div>
</template>

<script>

import rules from "@/utils/rules";
import {
  departStaffFullTimeHandover, departStaffFullTimeHandoverReply,
  departStaffFullTimeHandoverShow, departStaffFullTimeShow
} from "@/api/resignation";

import {QAHOST, upFile} from "@/config";
import {getBaseDataByOfferId, getOfferHtml} from "@/api/common";
import {getPageName, zhDate} from "@/utils";
import {downLoadOfferWord} from "@/api/entry";
import {reasonList, yesOrNo} from "@/utils/const";

export default {
  name: "offerList",
  data() {
    return {
      id: '',
      yesOrNo: yesOrNo,
      reasonList: reasonList,
      handoverForm: {
        xingming: '',
        gangwei: '',
        resignSubDate: '',
        lizhiriqi: '',
        socialInsuranceEnd: '',
        anyAgreement: '',
        lizhiyuanying: '',
        resignSalary: '',
        resignSalaryReleaseMonth: '',
        resignSignDate: '',


      },
      rules: {
        xingming: rules.mustInput,
        gangwei: rules.mustInput,
        resignSubDate: rules.mustDate,
        lizhiriqi: rules.mustDate,
        socialInsuranceEnd: rules.mustDate,
        anyAgreement: rules.mustSelect,
        lizhiyuanying: rules.mustSelect,
        resignSalary: rules.mustInput,
        resignSalaryReleaseMonth: rules.mustInput,
        resignSignDate: rules.mustDate,

      },
      isSubmit: true,
      sys_offer_file: '',
      upload_file: '',
      iframe: '',
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    }
  },
  created() {
    this.$emit("setIndex", '/admin/resignationList', "全职员工离职交接表", true);
    this.id = Number(this.$route.params.id)
    this.departStaffFullTimeHandoverShow()
  },
  components: {},
  methods: {
    async departStaffFullTimeHandoverShow() {
      const res = await departStaffFullTimeHandoverShow(this.id)
      if (res.data) {
        this.handoverForm = {
          xingming: res.data.xingming,
          gangwei: res.data.gangwei,
          resignSubDate: res.data.resignSubDate ? new Date(res.data.resignSubDate) : '',
          lizhiriqi: res.data.lizhiriqi ? new Date(res.data.lizhiriqi) : '',
          socialInsuranceEnd: res.data.socialInsuranceEnd ? new Date(res.data.socialInsuranceEnd) : '',
          anyAgreement: res.data.anyAgreement ? Number(res.data.anyAgreement) : '',
          lizhiyuanying: res.data.lizhiyuanying ? Number(res.data.lizhiyuanying) : '',
          resignSalary: res.data.resignSalary,
          resignSalaryReleaseMonth: res.data.resignSalaryReleaseMonth,
          resignSignDate: res.data.resignSignDate?new Date(res.data.resignSignDate):'',
        }
        this.sys_offer_file = res.data.sys_offer_file
        this.upload_file = res.data.upload_file ? QAHOST + '/' + res.data.upload_file : ''
        if (res.data.sys_offer_file) {
          const iframe = await getOfferHtml(getPageName(res.data.sys_offer_file))
          this.iframe = QAHOST + '/' + iframe.data + '?v=' + new Date().getTime()
          this.$refs.iframe.contentWindow.location.replace(this.iframe)
        }
      } else {
        const info = await getBaseDataByOfferId(this.id)
        const info2 = await departStaffFullTimeShow(this.id)
        this.handoverForm.xingming = info.data.staff.xingming
        this.handoverForm.gangwei = info.data.staff.gangwei
        this.handoverForm.resignSubDate = new Date(info2.data.tijiaoriqi)
        this.handoverForm.lizhiyuanying = info2.data.lizhiyuanying
      }
    },
    submitForm(formName) {
      console.log( this.handoverForm)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      console.log( this.handoverForm)
      let _state = await departStaffFullTimeHandover(
          this.id,
          this.handoverForm.xingming,
          this.handoverForm.gangwei,
          zhDate(new Date(this.handoverForm.resignSubDate)),
          zhDate(new Date(this.handoverForm.lizhiriqi)),
          zhDate(new Date(this.handoverForm.socialInsuranceEnd)),
          this.handoverForm.anyAgreement,
          this.handoverForm.lizhiyuanying,
          this.handoverForm.resignSalary,
          this.handoverForm.resignSalaryReleaseMonth,
          zhDate(new Date(this.handoverForm.resignSignDate)),
      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
        await this.departStaffFullTimeHandoverShow()
      } else {
        this.$message.error(_state.info)
      }
    },
    //  下载附件
    async downOffer() {
      if (!this.iframe) return this.$message.error('请编辑保存后生成')
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _data = await downLoadOfferWord(this.id, this.sys_offer_file)
      loading.close();
      let blob = _data.data
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.setAttribute('download', this.handoverForm.xingming + '离职交接表.docx');
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },
    handleSuccess(file) {
      if (file.status === 200) {
        this.upFile(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upFile(url) {
      await departStaffFullTimeHandoverReply(this.id, url)
      this.$message.success('上传成功')
      await this.departStaffFullTimeHandoverShow()
    },
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.preview {
  height: 100%;

  .preview-title {
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }

  .preview-info {
    width: 100%;
    height: calc(100vh - 330px);
    overflow-y: auto;
    padding: 0 30px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
  }
}
</style>
